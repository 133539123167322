<template>
    <div>
        <div class="h1 text-center mb-5">Our Services</div>
        <div v-for="(group, groupIndex) in serviceGroup" :key="groupIndex" :class="{'pt-5': groupIndex}" class="row gx-0 gx-lg-5 my-lg-5 align-items-center">
            <div :class="{'order-last': group.reverse}" class="col-5 d-none d-lg-block">
                <img class="w-100 px-5" :src="group.image">
            </div>
            <div class="col-12 col-lg-7">
                <div v-for="(service, serviceIndex) in group.services" :key="serviceIndex" :class="{'mt-4': serviceIndex}" class="card shadow-lg">
                    <div class="card-body p-5">
                        <div class="card-title h5 text-capitalize fw-bold mb-0">
                            <i :class="service.icon" class="fa-lg me-3 d-none d-lg-inline"></i>
                            <span>{{ service.title }}</span>
                        </div>
                        <div v-if="service.description" class="card-text mt-4" v-html="service.description" />
                        <div v-if="service.link.active" class="mt-4">
                            <router-link :to="{name: service.link.name}" custom v-slot="{ navigate }">
                                <button @click="navigate" @keypress.enter="navigate" class="btn btn-outline-grey px-4 d-flex align-items-center">
                                    <span>{{ service.link.text }}</span>
                                    <i :class="service.link.icon" class="fa-xs ms-2"></i>
                                </button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-4 my-lg-5 align-items-center">
            <div class="col-5 d-none d-lg-block order-last text-center">
                <img class="xero-big w-75 px-5" src="https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fvector%2Fxero.png?alt=media&token=5c0032d0-84e2-4c92-8d57-59f9dff80921">
            </div>
            <div class="col-12 col-lg-7">
                <div class="card shadow-lg">
                    <div class="card-body p-5">
                        <div class="card-title h5 d-flex align-items-center text-capitalize fw-bold mb-0">
                            <img class="xero me-3" src="https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fvector%2Fxero.png?alt=media&token=5c0032d0-84e2-4c92-8d57-59f9dff80921">
                            <span>Xero Beautiful Cloud Accounting Software</span>
                        </div>
                        <div class="card-text mt-4">
                            Try Xero free for your business and sample every feature with unlimited users — you’ll see how Xero makes running your business a breeze
                        </div>
                        <div class="d-block d-lg-flex mt-4">
                            <router-link :to="{name: 'XeroWorkshop'}" custom v-slot="{ navigate }">
                                <button @click="navigate" @keypress.enter="navigate" class="btn btn-outline-grey px-4 mb-3 mb-lg-0 me-lg-2 d-flex align-items-center">
                                    <span>Xero Workshop</span>
                                    <i class="fas fa-chart-pie ms-2"></i>
                                </button>
                            </router-link>
                            <router-link :to="{name: 'Service08'}" custom v-slot="{ navigate }">
                                <button @click="navigate" @keypress.enter="navigate" class="btn btn-outline-grey px-4 d-flex align-items-center">
                                    <span>Learn More</span>
                                    <i class="fas fa-external-link-alt fa-xs ms-2"></i>
                                </button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.card {
    border: 0;
}
.card-title {
    color: var(--navy);
}
.xero {
    width: 1.75em;
    height: 1.75em;
    filter: hue-rotate(40deg);
}
.xero-big {
    filter: hue-rotate(40deg);
}
</style>
<script>
import jsonService from '@/assets/json/services.json'

export default {
    data() {
        return {
            services: jsonService
        }
    },
    computed: {
        serviceGroup() {
            return [
                {
                    reverse: false,
                    services: [this.services[0]],
                    image: "https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fvector%2Fcertificate.svg?alt=media&token=295dd4c2-f897-4cb2-a012-35d2b9efccb4"
                },
                {
                    reverse: true,
                    services: [this.services[1]],
                    image: "https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fvector%2Ffolder_files.svg?alt=media&token=ed9f7206-093e-4131-bb3d-e75e2b4e09f0"
                },
                {
                    reverse: false,
                    services: this.filterService([2, 3]),
                    image: "https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fvector%2Fworking.svg?alt=media&token=378e7173-eaa0-46fe-93c2-00d324e9bc3d"
                },
                {
                    reverse: true,
                    services: [this.services[4]],
                    image: "https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fvector%2Fbest_place.svg?alt=media&token=20853424-4848-4747-be4f-5d97fbcd5176"
                },
                {
                    reverse: false,
                    services: this.filterService([5, 6]),
                    image: "https://firebasestorage.googleapis.com/v0/b/jnpaccounting-5fe5f.appspot.com/o/imgs%2Fvector%2Fdiscount.svg?alt=media&token=bb59c77b-df80-478a-a906-985786f1b5da"
                }
            ]
        }
    },
    methods: {
        filterService(indexes) {
            return [...this.services.filter((_, index) => indexes.includes(index))]
        }
    }
}
</script>