<template>
    <div class="background d-flex py-5">
        <div class="container d-flex justify-content-center align-items-center">
            <div class="row gap-5 align-items-center">
                <div class="col-12 col-lg-5 display-1 fw-bold text-center text-lg-start">
                    <span>JLEGAL</span>
                    <br/>
                    <span>THAILAND</span>
                </div>
                <div class="col-12 col-lg-6 my-auto">
                    <div class="fs-4 description py-auto text-center text-lg-start mb-4">
                        Professional Accounting and Tax advisory services by Thai CPA & English-Speaking Consultant. One stop service solution: online accounting, company registration & secretary for SMEs in Thailand
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.background {
    background: linear-gradient(-30deg, var(--corn), var(--navy));
    width: 100%;
    min-height: 50vh;
    color: white;
}
.description {
    color: #eeeeee;
}
.description > b {
    font-weight: bold;
    color: white;
}
.btn-service {
    background: #eeeeee;
    border: 0;
    color: var(--darkGrey);
    transition: .25s;
}
.btn-service:hover {
    color: var(--navy);
}
</style>