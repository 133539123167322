<template>
    <div>
        <Welcome />
        <div class="container py-5">
            <Services />
        </div>
    </div>
</template>
<script>
import Welcome from './Welcome.vue'
import Services from './Services.vue'

export default {
    components: {
        Welcome,
        Services
    }
}
</script>